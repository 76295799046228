import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'

interface NavBarProps {
  onSelect: (section: string) => void
}

const NavBar: React.FC<NavBarProps> = ({ onSelect }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const handleSelect = (section: string) => {
    onSelect(section)
    setMenuOpen(false)
  }

  return (
    <nav className="NavBar">
      <button className="Menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={menuOpen ? 'show' : ''}>
        <li>
          <button className="nav-link" onClick={() => handleSelect('blurb')}>
            About the Book
          </button>
        </li>
        <li>
          <button className="nav-link" onClick={() => handleSelect('about')}>
            About the Author
          </button>
        </li>
        <li>
          <button className="nav-link" onClick={() => handleSelect('prologue')}>
            Read the Prologue
          </button>
        </li>
        <li>
          <Link
            className="nav-link"
            to="/buy"
            onClick={() => setMenuOpen(false)}
          >
            Buy
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default NavBar
