import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Modal } from 'antd'
import './TextModal.css'

interface TextModalProps {
  title: string
  content: string
}

const TextModal = NiceModal.create(({ title, content }: TextModalProps) => {
  const modal = useModal()

  return (
    <Modal
      width="90%"
      className="modal"
      wrapClassName="modal-wrapper"
      onOk={() => modal.hide()}
      onClose={() => modal.hide()}
      onCancel={() => modal.hide()}
      open={modal.visible}
      afterClose={() => modal.remove()}
      okText="Close"
      footer={null}
    >
      <div className="TextModal-container">
        <h1 className="TextModal-title">{title}</h1>
        <div className="TextModal-content">
          {content.split('\n').map((line, index) => (
            <p
              key={index}
              className={
                index > 0
                  ? 'TextModal-paragraph indented'
                  : 'TextModal-paragraph'
              }
            >
              {line}
            </p>
          ))}
          <p
            key="separator"
            className="TextModal-paragraph"
            style={{ textAlign: 'center' }}
          >
            * * *
          </p>
        </div>
        <div className="TextModal-links">
          <a key="buy" href="/buy" className="link">
            Buy Now
          </a>
        </div>
        <button
          className="TextModal-close"
          onClick={() => {
            modal.hide()
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  )
})

export default TextModal
