import NiceModal from '@ebay/nice-modal-react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { IconContext } from 'react-icons'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './App'
import Buy from './Buy'
import './index.css'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
      <NiceModal.Provider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/buy" element={<Buy />} />
          </Routes>
        </BrowserRouter>
      </NiceModal.Provider>
    </IconContext.Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
