import React from 'react'

import './CoverSection.css'

interface CoverSectionProps {
  coverImage: string
  coverImageHd: string
  title: string
  tagline: string
  author: string
  useFullHeight?: boolean
}

const CoverSection: React.FC<CoverSectionProps> = ({
  coverImage,
  coverImageHd,
  title,
  tagline,
  author,
  useFullHeight = false,
}) => {
  return (
    <section className={`Cover-section ${useFullHeight ? 'full-height' : ''}`}>
      <div className="Cover-content">
        <div className="Text-content">
          <h1 className="Book-title">{title}</h1>

          <h2 className="Book-tagline">{tagline}</h2>
          <p className="Book-author">By {author}</p>
        </div>
        <div className="Cover-image">
          <img
            src={coverImage}
            srcSet={`${coverImage} 1x, ${coverImageHd} 2x`}
            alt="Book Cover"
            className="Book-cover-image"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  )
}

export default CoverSection
